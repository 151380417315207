<template>
  <b-overlay :show="showOverlay">

    <!--    Editor Modal  -->
    <b-modal
        id="modal-Editor"
        centered
        title="افزودن کد html"
        ok-title="ثبت"
        ok-only
        @ok.preventDefault()="customButtonClick"
    >
      <b-row>
        <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
        <b-col cols="12">
          <textarea class="form-control" v-model="rawHTML"></textarea>
        </b-col>

      </b-row>
    </b-modal>

    <b-card>
      <b-row>
        <b-col class="my-1" cols="12">
          <h2>About Us</h2>
        </b-col>
        <b-col cols="12" v-if="data" md="12">
          <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="data.content"></ckEditorMain>
        </b-col>
        <b-col class="my-3" cols="6">
          <b-button type="button" class="btn w-25" variant="primary" @click="updateData">Submit</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BCarousel,
  BCarouselSlide,
  BFormInput, BFormSelect, BInputGroup, BLink,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ckEditorMain from "@/views/components/ckEditorMain";
import {StaticPageGetByPageIdRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
export default {
  components: {
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: "Rule",
  title: "Rule",
  data() {
    return {
      showOverlay: false,
      logoPic: '',
      baseUrl: Helper.baseUrl,
      selectedBanner: null,
      shopInfo: [],
      imgUrl: '',
      rawHTML: '',
      banners:[],
      data:null,
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    setEditorContent(content){
      this.data.content = content
    },
    async getData() {
      let _this = this;
      _this.showOverlay = true;

      let staticPageGetByPageIdRequest = new StaticPageGetByPageIdRequest(_this);
      staticPageGetByPageIdRequest.setParams({pageId : 3})
      await staticPageGetByPageIdRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.data = content;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async updateData() {
      let _this = this;
      _this.showOverlay = true;

      let staticPageUpdateRequest = new StaticPageUpdateRequest(_this);
      staticPageUpdateRequest.setParams(_this.data);
      await staticPageUpdateRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getData();
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    customButtonClick() {
      this.shopInfo.description += this.rawHTML
      this.rawHTML = ''
    },
  }
}
</script>

<style scoped>

</style>
